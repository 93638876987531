<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="ประวัติการซื้อ"
        :filter="filter"
        @search="handleSearch"
        @clear="clearFilter"
      >
        <template v-slot:filter-option>
          <b-col>
            <InputText
              v-model="filter.billId"
              textFloat="หมายเลขบิล (POS Ref No.)"
              placeholder="หมายเลขบิล (POS Ref No.)"
              type="text"
              name="refSaleMember_billId"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputText
              v-model="filter.memberId"
              textFloat="หมายเลขสมาชิก (Member No.)"
              placeholder="หมายเลขสมาชิก (Member No.)"
              type="text"
              name="refSaleMember_memberId"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputText
              v-model="filter.name"
              textFloat="ชื่อสมาชิก (Member Name)"
              placeholder="ชื่อสมาชิก (Member Name)"
              type="text"
              name="refSaleMember_name"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputText
              v-model="filter.telephone"
              textFloat="เบอร์โทรศัพท์ (Telephone)"
              placeholder="เบอร์โทรศัพท์ (Telephone)"
              type="text"
              name="refSaleMember_telephone"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputDatePickerFilter
              textFloat="วันเริ่มต้น (Start Date)"
              name="fromDate"
              ref="fromDate"
              v-model="filter.fromDate"
              @input="changeFromDate"
              :v="$v.filter.fromDate"
              placeholder="DD/MM/YYYY"
              class="mb-2"
            />
          </b-col>
          <b-col>
            <InputDatePickerFilter
              textFloat="วันสิ้นสุด (End Date)"
              name="toDate"
              ref="toDate"
              v-model="filter.toDate"
              @input="onChangeToDate"
              :v="$v.filter.toDate"
              placeholder="DD/MM/YYYY"
              class="mb-2"
            />
          </b-col>
          <b-col>
            <InputSelect
              title="สาขา (Branch)"
              isRequired
              :options="branchList"
              v-model="filter.branchId"
              @onDataChange="val => (filter.branchId = val)"
              textField="name"
              valueField="id"
              class="mb-2"
            />
          </b-col>
          <b-col>
            <InputDatePickerFilter
              textFloat="วันสมัครเริ่มต้น (Registration Start Date)"
              name="registFromDate"
              ref="registFromDate"
              v-model="filter.registFromDate"
              @input="changeRegistFromDate"
              :v="$v.filter.registFromDate"
              placeholder="DD/MM/YYYY"
              class="mb-2"
            />
          </b-col>
          <b-col>
            <InputDatePickerFilter
              textFloat="วันสมัครสิ้นสุด (Registration End Date)"
              name="registToDate"
              ref="registToDate"
              v-model="filter.registToDate"
              @input="onChangeRegistToDate"
              :v="$v.filter.registToDate"
              placeholder="DD/MM/YYYY"
              class="mb-2"
            />
          </b-col>
          <b-col>
            <InputSelect
              title="ชนิดสินค้า"
              isRequired
              :options="articleTypeList"
              v-model="filter.filterType"
              @onDataChange="val => (filter.filterType = val)"
              textField="article_type"
              valueField="article_type"
              class="mb-2"
            />
          </b-col>
          <b-col>
            <InputSelect
              title="หมวดหมู่ (Category)"
              isRequired
              :options="ah2List"
              v-model="filter.ah2Id"
              @onDataChange="val => (filter.ah2Id = val)"
              textField="name"
              valueField="id"
              class="mb-2"
            />
          </b-col>
          <b-col>
            <InputSelect
              title="ราคาต่อบิล (Price)"
              isRequired
              :options="spendingRangList"
              v-model="filter.spendId"
              @onDataChange="val => (filter.spendId = val)"
              textField="name"
              valueField="id"
              class="mb-2"
            />
          </b-col>
        </template>
      </HeaderPanel>
      <div class="bg-white border-red mt-3">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
              class="table-main"
              :no-border-collapse="false"
            >
              <template v-slot:cell(member_id)="data">
                <router-link
                  :to="`/report/history-sale-category?memberId=${data.item.member_id}`"
                >
                  <u> {{ data.item.member_id }}</u>
                </router-link>
              </template>
              <template v-slot:cell(price_per_unit)="data">
                <div>{{ data.item.price_per_unit | numeral('0,0.00') }}</div>
              </template>
              <template v-slot:cell(discount)="data">
                <div>{{ data.item.discount | numeral('0,0.00') }}</div>
              </template>
              <template v-slot:cell(net_sale)="data">
                <div>{{ data.item.net_sale | numeral('0,0.00') }}</div>
              </template>
              <template v-slot:cell(article_type)="data">
                {{
                  data.item.article_type.toUpperCase() == 'ZSER'
                    ? 'Single Treatment'
                    : data.item.article_type.toUpperCase() == 'ZPACK'
                    ? 'Package'
                    : '-'
                }}
              </template>
              <template v-slot:cell(created_time)="data">
                <div>
                  {{
                    $moment(data.item.created_time)
                      .add(543, 'years')
                      .format($formatDateNewFull)
                  }}
                </div>
              </template>
              <template v-slot:cell(register_date)="data">
                <div>
                  {{
                    $moment(data.item.register_date, 'MM/DD/YYYY HH:mm:ss')
                      .add(543, 'years')
                      .format($formatDateNewFull)
                  }}
                </div>
              </template>
              <template v-slot:cell(is_consent)="data">
                <div v-if="data.item.is_consent">ยินยอม</div>
                <div v-else>รอยินยอม</div>
              </template>
              <template v-slot:cell(action)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <img
                    src="@/assets/images/icons/note.png"
                    alt="transfer"
                    class="action-img pointer"
                    width="20"
                    center
                    @click="$refs.modalNote.show(data.item)"
                  />
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
    </div>
    <ModalNote ref="modalNote" :branchId="branchId" />
  </div>
</template>

<script>
import OtherLoading from '@/components/loading/OtherLoading';
import HeaderPanel from '@/components/HeaderPanel';
import Pagination from '@/components/Pagination';
import InputSelect from '@/components/inputs/InputSelect';
import InputText from '@/components/inputs/InputText';
import ModalNote from '@/components/report/refSaleMember/ModalNote';
import InputDatePickerFilter from '@/components/inputs/InputDatePickerFilter';
import { mapGetters } from 'vuex';
import { minValue, maxValue } from 'vuelidate/lib/validators';

const minDate = (value, fromDate) => {
  if (!value) return true;
  let minDate = fromDate;
  return new Date(value) >= new Date(minDate);
};
const maxDate = (value, toDate = null) => {
  let maxDate = toDate || new Date();
  return new Date(maxDate) >= new Date(value);
};

export default {
  components: {
    OtherLoading,
    Pagination,
    HeaderPanel,
    InputSelect,
    InputText,
    ModalNote,
    InputDatePickerFilter
  },
  name: 'ReportRefSaleMember',
  data() {
    return {
      fields: [
        {
          key: 'branch_name',
          label: 'สาขา'
        },
        {
          key: 'invoice_no',
          label: 'Bill Ref.'
        },
        {
          key: 'created_time',
          label: 'วันที่'
        },
        {
          key: 'member_id',
          label: 'รหัสสมาชิก'
        },
        {
          key: 'first_name_th',
          label: 'ชื่อ'
        },
        {
          key: 'last_name_th',
          label: 'นามสกุล'
        },
        {
          key: 'telephone',
          label: 'เบอร์โทร'
        },
        {
          key: 'register_date',
          label: 'วันที่สมัคร'
        },
        {
          key: 'article_type',
          label: 'ประเภท'
        },
        {
          key: 'article_no',
          label: 'รหัสสินค้า'
        },
        {
          key: 'product_name',
          label: 'รายการ',
          tdClass: 'text-left'
        },
        {
          key: 'ah_2_name',
          label: 'AH2'
        },
        {
          key: 'ah_4_name',
          label: 'AH4'
        },
        {
          key: 'price_per_unit',
          label: 'ราคาต่อหน่วย (บาท)'
        },
        {
          key: 'discount',
          label: 'ส่วนลด'
        },
        {
          key: 'net_sale',
          label: 'ราคาสุทธิ(บาท)'
        },
        {
          key: 'source_name',
          label: 'ช่องทางการทำรายการ'
        },
        {
          key: 'is_consent',
          label: 'Consent status'
        },
        {
          key: 'action',
          label: '',
          tdClass: 'text-center'
        }
      ],
      items: [],
      isBusy: false,
      isLoadingData: false,
      rows: 0,
      filter: {
        branchId: 0,
        page: 1,
        take : 10,
        fromDate: null,
        toDate: null,
        registFromDate: null,
        registToDate: null,
        ah2Id: 0,
        billId: '',
        memberId: '',
        name: '',
        telephone: '',
        spendId: 0,
        filterType: 'ทั้งหมด'
      },
      pageOptions: [
        { value: 5, text: '5 / page' },
        { value: 10, text: '10 / page' },
        { value: 30, text: '30 / page' },
        { value: 50, text: '50 / page' },
        { value: 100, text: '100 / page' }
      ],
      selected: 0,
      isLoading: true,
      branchList: [],
      articleTypeList: [],
      spendingRangList: [],
      ah2List: [],
      memberTypeOptions: false,
      today: null
    };
  },
  validations: {
    filter: {
      fromDate: {
        maxValue(val, { toDate }) {
          return maxDate(val, toDate);
        }
      },
      toDate: {
        minValue(val, { fromDate }) {
          return minDate(val, fromDate);
        }
      },
      registFromDate: {
        maxValue(val, { registToDate }) {
          return maxDate(val, registToDate);
        }
      },
      registToDate: {
        minValue(val, { registFromDate }) {
          return minDate(val, registFromDate);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      branchId: 'getBranchId'
    })
  },
  created: async function () {
    this.today = this.$moment().format('YYYY-MM-DDT00:00:00');

    this.filter.ah2Id = this.$route.query.ah || 0;
    this.filter.fromDate =
      this.$route.query.startDate ||
      this.$moment().startOf('year').format('YYYY-MM-DDT00:00:00');
    this.filter.toDate = this.$route.query.endDate || null;
    this.filter.telephone = this.$route.query.telephone || '';
    this.filter.memberId = this.$route.query.memberId || '';
    this.filter.name = this.$route.query.memberName || '';

    this.getBranchList();
    this.getArticleTypeList();
    this.getSpendingRangeList();
    this.getListAh2List();
    await this.getList();
  },
  methods: {
    getList: async function () {
      this.$v.filter.$touch();
      if (this.$v.filter.$error) return;

      this.isBusy = true;
      let payload = { ...this.filter };
      payload.skip = (payload.page - 1) * payload.take;
      payload.filterType =
        payload.filterType == 'ทั้งหมด' ? 0 : payload.filterType;
      payload.from_date = payload.fromDate;
      payload.branch_id = payload.branchId;
      payload.to_date = payload.toDate;
      payload.regist_from_date = payload.registFromDate;
      payload.regist_to_date = payload.registToDate;
      payload.ah_2_id = payload.ah2Id;
      payload.member_id = payload.memberId;
      payload.member_name = payload.name;
      payload.spend_id = payload.spendId;

      this.isLoadingData = true;
      await this.axios
        .post(`${this.$baseUrl}/report/get_ref_sale_member`, payload)
        .then(data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.items = data.detail;
            this.rows = data.total_count;
            this.isLoadingData = false;
          }
        });

      this.isBusy = false;
    },
    getBranchList: async function () {
      let list = [{ id: 0, name: 'ทั้งหมด' }];

      await this.axios.get(`${this.$baseUrl}/branch/list`).then(data => {
        if (data.result == 1) {
          data.detail.map(el => {
            el.id, (el.name = `${el.plant_id} ${el.name}`);
          });
          this.branchList = list.concat(data.detail);
        }
      });
    },
    getArticleTypeList: async function () {
      let list = [{ article_type: 'ทั้งหมด' }];
      await this.axios
        .get(`${this.$baseUrl}/report/get_article_type`)
        .then(data => {
          if (data.result == 1) {
            this.articleTypeList = list.concat(data.detail);
          }
        });
    },
    getSpendingRangeList: async function () {
      await this.axios
        .get(`${this.$baseUrl}/dropdown/get_spending_range`)
        .then(data => {
          if (data.result == 1) {
            this.spendingRangList = data.detail;
          }
        });
    },
    getListAh2List: async function () {
      let list = [{ id: 0, name: 'ทั้งหมด' }];
      await this.axios
        .get(`${this.$baseUrl}/ah/get_ah2_view_list`)
        .then(data => {
          if (data.result == 1) {
            this.ah2List = list.concat(data.detail);
          }
        });
    },
    handleSearch() {
      this.pagination(1);
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    clearFilter() {
      this.$v.filter.$reset();

      this.filter = {
        branchId: 0,
        page: 1,
        take : 10,
        fromDate: null,
        toDate: null,
        registFromDate: null,
        registToDate: null,
        ah2Id: 0,
        billId: '',
        memberId: '',
        name: '',
        telephone: '',
        spendId: 0,
        filterType: 'ทั้งหมด'
      };
      this.getList();
    },
    changeFromDate(val) {
      this.filter.fromDate = val;
    },
    onChangeToDate(val) {
      this.filter.toDate = val;
    },
    changeRegistFromDate(val) {
      this.filter.registFromDate = val;
    },
    onChangeRegistToDate(val) {
      this.filter.registToDate = val;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .table-main {
  table {
    min-width: max-content;
  }
}
.time {
  color: #afafaf;
}
.line-clamp2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.badge {
  font-size: 85%;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  max-width: max-content;
  font-weight: 100;
}
.badge-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.icon {
  font-size: 24px;
  color: #000 !important;
}
</style>
